import { Box, Button, Center, Stack } from "@chakra-ui/react";
import React from "react";
import { QRCode } from "react-qrcode-logo";
import logo from "./../assets/photo_2024-02-07_14-05-01@2x.jpg";

interface QRCodeGeneratorProps {
  url: string;
  //   iconUrl: string;
}

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({ url }) => {
  const downloadQRCode = () => {
    const canvas = document.getElementById("qrcode") as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qrcode.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <Center>
      <Box>
        <QRCode
          id="qrcode"
          value={url}
          logoImage={logo}
          logoWidth={60}
          eyeRadius={[10, 10, 10, 10]}
          size={256}
        />
        <Stack direction="row" spacing={4} justifyContent="center" mt={4}>
          <Button onClick={downloadQRCode} colorScheme="teal">
            Download QR Code
          </Button>
        </Stack>
      </Box>
    </Center>
  );
};

export default QRCodeGenerator;
