export const getConfig = (userInfo: any) => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userInfo?.token}`,
  },
});

export const getConfigForFileUpload = (userInfo: any) => ({
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${userInfo?.token}`,
    maxBodyLength: Infinity,
  },
});
