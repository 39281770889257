import {
  Box,
  Flex,
  IconButton,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <Box
      width={"full"}
      bg={useColorModeValue("gray.100", "gray.700")}
      py={4}
      px={6}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box mb={{ base: 4, md: 0 }}>
          <Text fontSize="sm">© 2024 Maki Gifts</Text>
        </Box>
        <Box mb={{ base: 4, md: 0 }}>
          <Link mx={2} fontSize="sm" href="#">
            Terms of Service
          </Link>
          <Link mx={2} fontSize="sm" href="#">
            Privacy Policy
          </Link>
          <Link mx={2} fontSize="sm" href="#">
            Contact Us
          </Link>
        </Box>
        <Flex justifyContent={"center"}>
          <IconButton
            as={Link}
            // href="https://facebook.com"
            aria-label="Facebook"
            icon={<FaFacebook />}
            variant="solid"
            isRound
            marginX="0.5rem"
            colorScheme="blue"
          />
          <IconButton
            as={Link}
            // href="https://twitter.com"
            aria-label="Twitter"
            icon={<FaTwitter />}
            variant="solid"
            isRound
            marginX="0.5rem"
            colorScheme="twitter"
          />
          <IconButton
            as={Link}
            // href="https://instagram.com"
            aria-label="Instagram"
            icon={<FaInstagram />}
            variant="solid"
            isRound
            marginX="0.5rem"
            colorScheme="pink"
          />
          <IconButton
            as={Link}
            // href="https://instagram.com"
            aria-label="Instagram"
            icon={<FaInstagram />}
            variant="solid"
            isRound
            marginX="0.5rem"
            colorScheme="pink"
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
