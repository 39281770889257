import { ChakraProvider, theme } from "@chakra-ui/react";
import HomePage from "./Pages/HomePage";
import { HashRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "./Pages/PageNotFound";
import TextPage from "./Pages/TextPage";
import VideoPage from "./Pages/VideoPage";
import PicturePage from "./Pages/PicturePage";
import SignInSignUpLayout from "./components/SignInSignUpLayout";
import SignInPage from "./Pages/SignInPage";
import SignUpPage from "./Pages/SignUpPage";
import SigendInLayout from "./components/SigendInLayout";
import Dashboard from "./Pages/Admin/Dashboard";
import VideoAdminPage from "./Pages/Admin/Video/VideoAdminPage";
import AddEditVideoPage from "./Pages/Admin/Video/AddEditVideoPage";
import PictureAdminPage from "./Pages/Admin/Picture/PictureAdminPage";
import TextAdminPage from "./Pages/Admin/Text/TextAdminPage";
import AddEditTextPage from "./Pages/Admin/Text/AddEditTextPage";
import LandingAdminPage from "./Pages/Admin/Landing/LandingAdminPage";
import SocialAdminPage from "./Pages/Admin/SocialLinks/SocialAdminPage";
import UserPage from "./Pages/Admin/User/UserPage";
import PermissionPage from "./Pages/Admin/Permission/PermissionPage";
import RolesPage from "./Pages/Admin/Role/RolesPage";
import AboutUsAdminPage from "./Pages/Admin/AboutUs/AboutUsAdminPage";

export const App = () => {
  const routes = [
    {
      path: "",
      element: <HomePage />,
    },
    {
      path: "t/:id",
      element: <TextPage />,
    },
    {
      path: "v/:id",
      element: <VideoPage />,
    },
    {
      path: "p/:id",
      element: <PicturePage />,
    },
    {
      path: "sign-in",
      element: <SignInSignUpLayout children={<SignInPage />} />,
    },
    {
      path: "sign-up",
      element: <SignInSignUpLayout children={<SignUpPage />} />,
    },

    {
      path: "v1",
      element: <SigendInLayout children={<Dashboard />} />,
    },
    {
      path: "v1/video",
      element: <SigendInLayout children={<VideoAdminPage />} />,
    },
    {
      path: "v1/video/add",
      element: <SigendInLayout children={<AddEditVideoPage />} />,
    },
    {
      path: "v1/video/edit/:id",
      element: <SigendInLayout children={<AddEditVideoPage isEdit={true} />} />,
    },
    {
      path: "v1/picture",
      element: <SigendInLayout children={<PictureAdminPage />} />,
    },

    {
      path: "v1/text",
      element: <SigendInLayout children={<TextAdminPage />} />,
    },
    {
      path: "v1/text/add",
      element: <SigendInLayout children={<AddEditTextPage />} />,
    },
    {
      path: "v1/text/edit/:id",
      element: <SigendInLayout children={<AddEditTextPage isEdit={true} />} />,
    },
    {
      path: "v1/landing",
      element: <SigendInLayout children={<LandingAdminPage />} />,
    },
    {
      path: "v1/about-us",
      element: <SigendInLayout children={<AboutUsAdminPage />} />,
    },
    {
      path: "v1/social",
      element: <SigendInLayout children={<SocialAdminPage />} />,
    },
    //
    {
      path: "v1/users",
      element: <SigendInLayout children={<UserPage />} />,
    },
    {
      path: "v1/permission",
      element: <SigendInLayout children={<PermissionPage />} />,
    },
    {
      path: "v1/role",
      element: <SigendInLayout children={<RolesPage />} />,
    },
  ];

  return (
    <HashRouter>
      <ChakraProvider theme={theme}>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ChakraProvider>
    </HashRouter>
  );
};
