import {
  useDisclosure,
  useToast,
  Button,
  IconButton,
  Container,
  Box,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Switch,
  Divider,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../../components/Components";
import PageHeader from "../../../components/PageHeader";
import {
  createAboutUs,
  deleteAboutUs,
  getAboutUs,
  updateAboutUs,
} from "../../../Redux/action/aboutus.action";
import { BiStrikethrough, BiHighlight } from "react-icons/bi";
import {
  FaHeading,
  FaBold,
  FaItalic,
  FaUnderline,
  FaListUl,
  FaListOl,
  FaQuoteRight,
  FaLink,
  FaIndent,
  FaOutdent,
  FaTable,
  FaUndo,
  FaRedo,
} from "react-icons/fa";
import {
  convertFromRaw,
  convertToRaw,
  DefaultDraftBlockRenderMap,
  Editor,
  EditorState,
  RichUtils,
} from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { Map } from "immutable";

const AboutUsAdminPage = () => {
  const [loading, setLoading] = useState(false);
  // const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  // const [shareAboutUsToDelete, setshareAboutUsToDelete] = useState<any>(null);

  // //////////////////
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const styleMap = {
    STRIKETHROUGH: {
      textDecoration: "line-through",
    },
    HIGHLIGHT: {
      backgroundColor: "yellow",
    },
    // Add more custom styles as needed
  };
  const blockRenderMap = DefaultDraftBlockRenderMap.merge(
    Map({
      "header-one": {
        element: "h1",
      },
      "header-two": {
        element: "h2",
      },
      "header-three": {
        element: "h3",
      },
    })
  );

  const [formData, setFormData] = useState({
    text: "",
    link: "",
    media: "",
    status: false,
  });

  // const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();
  const toast = useToast();

  const _getAboutUs = useSelector(
    (state: { getAboutUs: any }) => state.getAboutUs
  );
  const { loading: aboutUsLoading, aboutUs, error } = _getAboutUs;

  // const _deleteAboutUs = useSelector(
  //   (state: { deleteAboutUs: any }) => state.deleteAboutUs
  // );
  // const {
  //   loading: shareTextDeleteLoading,
  //   aboutUs: deletedAboutUs,
  //   error: deleteAboutUsError,
  // } = _deleteAboutUs;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  // const handleDelete = (shareText: any) => {
  //   // setshareTextToDelete(shareText);
  //   setDeleteConfirmationOpen(true);
  // };

  // const handleConfirmDelete = () => {
  //   // Perform the delete action here
  //   dispatch<any>(deleteAboutUs(toastPositions, toast)).then(() => {
  //     setTimeout(() => {
  //       dispatch<any>(getAboutUs(toastPositions, toast));
  //     }, 200);
  //   });
  //   setDeleteConfirmationOpen(false);
  // };

  useEffect(() => {
    dispatch<any>(getAboutUs(toastPositions, toast));
    setLoading(true);
  }, [dispatch, toast]);

  useEffect(() => {
    // const startIndex = (page - 1) * PAGE_SIZE;
    // const endIndex = startIndex + PAGE_SIZE;

    // setData(aboutUs);
    // setFilteredData(
    //   data
    //     ?.filter((d) =>
    //       d.title?.toLowerCase().includes(searchTerm.toLowerCase())
    //     )
    //     .slice(startIndex, endIndex)
    // );
    setLoading(false);

    // console.log({ aboutUs, toastPositions });
    aboutUsLoading ? setLoading(true) : setLoading(false);
  }, [aboutUs, aboutUsLoading]);

  useEffect(() => {
    if (aboutUs) {
      setFormData({
        text: aboutUs.text,
        link: aboutUs.link,
        media: aboutUs.media,
        status: aboutUs.status,
      });

      // Convert markdown text to Draft.js editor state
      // const rawContentState = markdownToDraft(formData.text);
      // const rawContentState = markdownToDraft(aboutUs.text);
      // const contentState = convertFromRaw(rawContentState);
      // console.log(contentState);
      // setEditorState(EditorState.createWithContent(contentState));
      const rawContentState = markdownToDraft(aboutUs.text); // Use aboutUs.text directly
      // console.log("Converted markdown to raw content state:", rawContentState);

      const contentState = convertFromRaw(rawContentState);
      // console.log(
      //   "Converted raw content state to Draft.js content state:",
      //   contentState
      // );

      setEditorState(EditorState.createWithContent(contentState));
      // console.log(
      //   "Editor state set with content:",
      //   EditorState.createWithContent(contentState)
      // );
    }
  }, [aboutUs]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleEditorChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const markdown = draftToMarkdown(rawContentState);
    setFormData((prevData) => ({ ...prevData, text: markdown }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const form = new FormData();
    form.append("text", formData.text);
    form.append("link", formData.link);
    form.append("status", JSON.stringify(formData.status));

    if (aboutUs) {
      dispatch<any>(updateAboutUs(form, toastPositions, toast));
    } else {
      dispatch<any>(createAboutUs(formData, toastPositions, toast));
    }

    // navigate("/v1/text");
  };

  const undo = () => {
    setEditorState(EditorState.undo(editorState));
  };

  const redo = () => {
    setEditorState(EditorState.redo(editorState));
  };

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const toggleInlineStyle = (style: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const toggleBlockType = (blockType: string) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toolbarButton = (
    onClick: () => void,
    icon: JSX.Element,
    label: string
  ) => (
    <IconButton
      onClick={onClick}
      icon={icon}
      aria-label={label}
      size="sm"
      variant="outline"
      borderWidth={0}
      colorScheme="teal"
    />
  );

  return (
    <>
      <PageHeader
        listLength={0}
        name={`About Us Doc`}
        subName="Please fill out the form"
      />

      {!loading ? (
        <Container
          borderWidth={"1px"}
          borderRadius={"15px"}
          p={"15px"}
          m={"15px"}
          width={"unset"}
          maxW={"7xl"}
          position={"relative"}
          onSubmit={handleSubmit}
          as={"form"}
        >
          <Box
            position={"absolute"}
            right={"1rem"}
            top={"-5rem"}
            textAlign="right"
          >
            <Button px={10} type="submit" colorScheme="teal">
              {aboutUs ? "Update" : "Save"}
            </Button>
          </Box>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel mb={0} fontSize={"lg"} htmlFor="bio">
                link
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="link-helptext">
                Add link here for the text
              </FormHelperText>
              <Input
                id="link"
                name="link"
                placeholder="add link here"
                value={formData.link}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel mb={0} fontSize={"lg"}>
                Status
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="status-helptext">
                On means this is shared and available for public.
              </FormHelperText>
              <Switch
                colorScheme="teal"
                name="status"
                isChecked={formData.status}
                onChange={handleSwitchChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel mb={0} fontSize={"lg"} htmlFor="bio">
                About Us
              </FormLabel>
              <FormHelperText mb={2} mt={0} id="text-helptext">
                Add text like a memory or anything that describes the text
              </FormHelperText>

              <Box
                p={4}
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                // bg={useColorModeValue("gray.50", "gray.700")}
              >
                <Stack
                  background={"#86868640"}
                  padding={"10px"}
                  flexWrap={"wrap"}
                  borderRadius={"10px"}
                  position={"sticky"}
                  top={"80px"}
                  zIndex={"111"}
                  backdropFilter={"blur(20px)"}
                  margin={"0 10px 14px 10px "}
                  direction="row"
                  spacing={2}
                  mb={4}
                >
                  {toolbarButton(
                    () => toggleBlockType("header-one"),
                    <FaHeading />,
                    "Heading 1"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("header-two"),
                    <FaHeading />,
                    "Heading 2"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("header-three"),
                    <FaHeading />,
                    "Heading 3"
                  )}
                  {toolbarButton(
                    () => toggleInlineStyle("BOLD"),
                    <FaBold />,
                    "Bold"
                  )}
                  {toolbarButton(
                    () => toggleInlineStyle("ITALIC"),
                    <FaItalic />,
                    "Italic"
                  )}
                  {toolbarButton(
                    () => toggleInlineStyle("UNDERLINE"),
                    <FaUnderline />,
                    "Underline"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("unordered-list-item"),
                    <FaListUl />,
                    "Bulleted List"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("ordered-list-item"),
                    <FaListOl />,
                    "Numbered List"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("blockquote"),
                    <FaQuoteRight />,
                    "blockquote"
                  )}
                  {toolbarButton(
                    () => toggleInlineStyle("STRIKETHROUGH"),
                    <BiStrikethrough />,
                    "Strikethrough"
                  )}
                  {toolbarButton(
                    () => toggleInlineStyle("HIGHLIGHT"),
                    <BiHighlight />,
                    "Highlight"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("unstyled"),
                    <FaLink />,
                    "Unstyled"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("indent"),
                    <FaIndent />,
                    "Indent"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("outdent"),
                    <FaOutdent />,
                    "Outdent"
                  )}
                  {toolbarButton(
                    () => toggleBlockType("atomic"),
                    <FaTable />,
                    "Atomic"
                  )}
                  {toolbarButton(undo, <FaUndo />, "Undo")}
                  {toolbarButton(redo, <FaRedo />, "Redo")}
                </Stack>
                <Box
                  p={2}
                  bg="white"
                  border="1px solid"
                  borderColor="gray.200"
                  background={"transparent"}
                  borderRadius="md"
                >
                  <Editor
                    spellCheck
                    customStyleMap={styleMap}
                    editorState={editorState}
                    blockRenderMap={blockRenderMap}
                    handleKeyCommand={handleKeyCommand}
                    onChange={handleEditorChange}
                    placeholder="Type something..."
                  />
                </Box>
              </Box>
            </FormControl>
          </Stack>
          <Divider my={6} />
          <Box textAlign="right">
            <Button px={10} type="submit" colorScheme="teal">
              {aboutUs ? "Update" : "Save"}
            </Button>
          </Box>
        </Container>
      ) : (
        <Loading />
      )}
    </>
  );
};
// !loading && <NoData icon={BsFileText} />
// {/* delete */}
// <Modal
//   isOpen={deleteConfirmationOpen}
//   onClose={() => setDeleteConfirmationOpen(false)}
//   size="sm"
//   isCentered
// >
//   <ModalOverlay />
//   <ModalContent>
//     <ModalHeader>Delete Text File</ModalHeader>
//     <ModalCloseButton />
//     <ModalBody>
//       <Text>Are you sure you want to delete this Text File?</Text>
//     </ModalBody>
//     <ModalFooter gap={"10px"}>
//       <Button colorScheme="red" onClick={handleConfirmDelete}>
//         Delete
//       </Button>
//       <Button onClick={() => setDeleteConfirmationOpen(false)}>
//         Cancel
//       </Button>
//     </ModalFooter>
//   </ModalContent>
// </Modal>

export default AboutUsAdminPage;
