import axios from "axios";
import { handleErrorMessage } from "../../interceptor/ErrorHandler";
import {
  GET_ALL_ACTIVE_SOCIAL_LINKS_REQUEST,
  GET_ALL_ACTIVE_SOCIAL_LINKS_SUCCESS,
  GET_ALL_ACTIVE_SOCIAL_LINKS_FAIL,
  GET_ALL_SOCIAL_LINKS_REQUEST,
  GET_ALL_SOCIAL_LINKS_SUCCESS,
  GET_ALL_SOCIAL_LINKS_FAIL,
  GET_SOCIAL_LINKS_BY_ID_REQUEST,
  GET_SOCIAL_LINKS_BY_ID_SUCCESS,
  GET_SOCIAL_LINKS_BY_ID_FAIL,
  CREATE_SOCIAL_LINKS_REQUEST,
  CREATE_SOCIAL_LINKS_SUCCESS,
  CREATE_SOCIAL_LINKS_FAIL,
  UPDATE_SOCIAL_LINKS_REQUEST,
  UPDATE_SOCIAL_LINKS_SUCCESS,
  UPDATE_SOCIAL_LINKS_FAIL,
  DELETE_SOCIAL_LINKS_REQUEST,
  DELETE_SOCIAL_LINKS_SUCCESS,
  DELETE_SOCIAL_LINKS_FAIL,
} from "../constant/socialLinks.constant";
import { UseToastOptions } from "@chakra-ui/react";
import API_BASE_URL from "../../util/config";
import { getConfig } from "./common/action";

export const getAllActiveSocialLinks =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ALL_ACTIVE_SOCIAL_LINKS_REQUEST });

      const { data } = await axios.get(`${API_BASE_URL}socialLinks/get/active`);

      dispatch({
        type: GET_ALL_ACTIVE_SOCIAL_LINKS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ALL_ACTIVE_SOCIAL_LINKS_FAIL
      );
    }
  };

export const getAllSocialLinks =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ALL_SOCIAL_LINKS_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
  const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}socialLinks`, config);

      dispatch({
        type: GET_ALL_SOCIAL_LINKS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ALL_SOCIAL_LINKS_FAIL
      );
    }
  };

export const getSocialLinksById =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_SOCIAL_LINKS_BY_ID_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
  const config = getConfig(userInfo);
      const { data } = await axios.get(
        `${API_BASE_URL}socialLinks/${id}`,
        config
      );

      dispatch({
        type: GET_SOCIAL_LINKS_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_SOCIAL_LINKS_BY_ID_FAIL
      );
    }
  };
  export const createSocialLinks =
  (
    socialLinksData: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: CREATE_SOCIAL_LINKS_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.post(
        `${API_BASE_URL}socialLinks`,
        socialLinksData,
        config
      );
      dispatch({
        type: CREATE_SOCIAL_LINKS_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `Social links created successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        CREATE_SOCIAL_LINKS_FAIL
      );
    }
  };

export const updateSocialLinks =
  (
    id: any,
    socialLinksData: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: UPDATE_SOCIAL_LINKS_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.put(
        `${API_BASE_URL}socialLinks/${id}`,
        socialLinksData,
        config
      );
      dispatch({
        type: UPDATE_SOCIAL_LINKS_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `Social links updated successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        UPDATE_SOCIAL_LINKS_FAIL
      );
    }
  };

export const deleteSocialLinks =
  (
    id: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: DELETE_SOCIAL_LINKS_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.delete(
        `${API_BASE_URL}socialLinks/${id}`,
        config
      );
      dispatch({
        type: DELETE_SOCIAL_LINKS_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `Social links deleted successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        DELETE_SOCIAL_LINKS_FAIL
      );
    }
  };
