import {
  Box,
  Icon,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import WelcomeMessage from "../../components/WelcomeMessage";
import {
  FaUser,
  FaCogs,
  FaVideo,
  FaPhotoVideo,
  FaTextHeight,
  FaUsers,
} from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const links = [
    { name: "Videos", icon: FaVideo, href: "/v1/video" },
    { name: "Picture", icon: FaPhotoVideo, href: "/v1/picture" },
    { name: "Text", icon: FaTextHeight, href: "/v1/text" },
    { name: "Users", icon: FaUsers, href: "/v1/users" },
    { name: "Profile", icon: FaUser, href: "/profile" },
    { name: "Settings", icon: FaCogs, href: "/settings" },
  ];

  const signUp = useSelector((state: { signUp: any }) => state.signUp);
  const { loading, error, userInfo } = signUp;

  return (
    <Box p={5}>
      <WelcomeMessage
        isAdmin={userInfo?.userInfo?.role?.name === "Admin"}
        userInfo={userInfo?.userInfo}
      />

      <Box mt={6}>
        <Text mb={1} fontWeight={"bold"} fontSize={"25px"}>
          Go To{" "}
        </Text>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 2, lg: 4 }} spacing={4}>
          {links.map((link) => (
            <LinkBox key={link.name} icon={link.icon} href={link.href}>
              {link.name}
            </LinkBox>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Dashboard;

const LinkBox = ({ icon, href, children }: any) => {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const hoverBgColor = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.800", "gray.200");

  return (
    <Link
      as={RouterLink}
      to={href}
      _hover={{ textDecoration: "none", bg: hoverBgColor }}
      bg={bgColor}
      borderRadius="md"
      p={4}
      display="flex"
      alignItems="center"
      boxShadow="md"
    >
      <Icon as={icon} w={6} h={6} mr={4} color="teal.500" />
      <Text fontSize="lg" color={textColor}>
        {children}
      </Text>
    </Link>
  );
};
