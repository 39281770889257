import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
   Flex,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import ErrorCard from "./ErrorCard";
import { Loading } from "../Components";
import EmptyMessage from "./EmptyMessage";
// import { NoData } from "../../../../Components/Util/NoData";
// import ErrorCard from "../../../../Components/Util/ErrorCard";
// import { Loading } from "../../../../Components/Util/Loading";
// import EmptyMessage from "../../../../Components/Util/EmptyMessage";

interface CommonTableProps<T> {
  title: string;
  color: string;
  caption: string[];
  tableData: T[];
  renderRow: (row: T) => React.ReactNode;
  pageSize?: number;
  formComponent?: React.ReactNode;
  showModal: boolean;
  handleAddButtonClick: () => void;
  handleCloseModal: () => void;
  handleFormSubmit: () => void;
  error?: string | null;
  loading?: boolean;
}

const CommonTable = <T,>({
  caption,
  tableData,
  title,
  color,
  renderRow,
  pageSize = 5,
  formComponent,
  handleAddButtonClick,
  handleCloseModal,
  handleFormSubmit,
  showModal,
  error,
  loading,
}: CommonTableProps<T>) => {
  const textColor = useColorModeValue("gray.800", "white");
  const boxColor = useColorModeValue("white", "gray.800");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = tableData?.filter((row: any) => {
    if (row.orderItem && Array.isArray(row.orderItem)) {
      for (const item of row.orderItem) {
        if (item.product && item.product?.name) {
          if (
            item.product?.name
              ?.toLowerCase()
              .includes(searchTerm?.toLowerCase())
          ) {
            return true;
          }
        }
      }
    }

    return (
      (row.user &&
        row.user.name?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
      (row.username &&
        row.username?.toLowerCase().includes(searchTerm?.toLowerCase())) ||
      row.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  });

  // console.log({ tableData, filteredData });

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = filteredData?.slice(startIndex, endIndex);

  const isFirstPage = currentPage === 1;
  const isLastPage = endIndex >= tableData?.length;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const isLoggedIn = true;

  return (
    <>
      <Card
        // ml={isLoggedIn ? { base: 0, md: 60 } : { base: 0, md: 0 }}
        my={{ base: "40px", md: "35px" }}
        bg={boxColor}
        overflow={"hidden"}
        borderRadius={"lg"}
        padding={"14px"}
        boxShadow={"sm"}
      >
        <Card
          bg={"transparent"}
          boxShadow={"none"}
          overflowX={{ sm: "auto", xl: "hidden" }}
        >
          <Flex justify="space-between" align="center">
            <CardHeader padding={"5px 15px"}>
              <Text
                borderColor={color}
                // borderBottomRadius={"10px"}
                borderBottomWidth={"2px"}
                paddingX={"10px"}
                fontSize="xl"
                color={textColor}
                fontWeight="bold"
              >
                {title}
              </Text>
            </CardHeader>
            <Box display={"flex"} ml="auto" flexShrink={0}>
              <Button
                onClick={handleAddButtonClick}
                mr={5}
                px={8}
                colorScheme="green"
              >
                Add {title}
              </Button>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <InputRightElement>
                  <SearchIcon color="gray.400" />
                </InputRightElement>
              </InputGroup>
            </Box>
          </Flex>
          <CardBody>
            <Table minWidth={"60rem"} variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  {caption?.map((caption, idx) => (
                    <Th
                      color="gray.400"
                      key={idx}
                      ps={idx === 0 ? "0px" : "unset"}
                      textAlign={caption === "Actions" ? "center" : "unset"}
                    >
                      {caption}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {paginatedData?.map((row: T, index: number) => (
                  <Tr key={index}>{renderRow(row)}</Tr>
                ))}
              </Tbody>
            </Table>
            {error && !loading && (
              <Flex
                height={"50vh"}
                width={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box width={"200px"}>
                  <ErrorCard message={error} />
                </Box>
              </Flex>
            )}
            {!error && loading && (
              <Flex
                height={"50vh"}
                width={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box width={"200px"}>
                  <Loading />
                </Box>
              </Flex>
            )}
            {!error && !loading && (!tableData || tableData.length === 0) && (
              <Flex
                height={"50vh"}
                width={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box width={"200px"}>
                  <EmptyMessage message={`No ${title} yet`} />
                </Box>
              </Flex>
            )}
          </CardBody>
        </Card>
        <Text my={"5px"} color={"gray.400"}>
          Total data: {tableData?.length}
        </Text>
        {filteredData?.length > pageSize && (
          <Box
            mt={6}
            display={"flex"}
            alignItems={"center"}
            gap={"10px"}
            textAlign="start"
          >
            <Button
              padding="5px 10px"
              height="unset"
              variant="outline"
              isDisabled={isFirstPage}
              onClick={() => handlePageChange(currentPage - 1)}
              colorScheme={isFirstPage ? "gray" : "blue"}
            >
              Previous
            </Button>

            <Box textAlign="center">
              {Array.from(
                Array(Math.ceil(filteredData?.length / pageSize)),
                (_, i) => (
                  <Button
                    key={i + 1}
                    padding="5px 10px"
                    height="unset"
                    variant="outline"
                    // variant={currentPage === i + 1 ? 'solid' : 'outline'}
                    size="sm"
                    mr={"3px"}
                    onClick={() => handlePageChange(i + 1)}
                    colorScheme={currentPage === i + 1 ? "blue" : "gray"}
                    disabled={currentPage === i + 1}
                  >
                    {i + 1}
                  </Button>
                )
              )}
            </Box>

            <Button
              padding="5px 10px"
              height="unset"
              variant="outline"
              isDisabled={isLastPage}
              onClick={() => handlePageChange(currentPage + 1)}
              colorScheme={isLastPage ? "gray" : "blue"}
            >
              Next
            </Button>
          </Box>
        )}
      </Card>

      {/* Modal */}
      <Modal
        scrollBehavior="inside"
        isOpen={showModal}
        onClose={handleCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add {title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{formComponent && formComponent}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CommonTable;
