import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Badge, Box, IconButton, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { truncateWords } from "../../../util/uitil";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

interface TextCardProps {
  shareText: any;
  onDelete: (shareText: any) => void;
}

const TextCard: React.FC<TextCardProps> = ({ shareText, onDelete }) => {
  return (
    <Box
      backgroundSize={"cover !important"}
      // background={
      //   thumbnail
      //     ? `linear-gradient(360deg, rgb(0 0 0 ), rgba(0, 0, 0, 0) 100%) ,center url(${thumbnail})`
      //     : "gray"
      // }
      minWidth="200px"
      minHeight="300px"
      position="relative"
      p={4}
      borderRadius="lg"
      boxShadow="lg"
      borderWidth={"1px"}
    >
      <Badge
        backgroundColor={shareText.status ? "#4fd1c5" : "#d14f70"}
        position={"absolute"}
        right={"10px"}
        top={"10px"}
        zIndex={1}
        borderRadius="full"
        color={"black"}
        px="2"
      >
        {shareText.status ? "Active" : "Deactive"}
      </Badge>

      <Stack
        direction="column"
        spacing={2}
        position="absolute"
        bottom="30px"
        right="13px"
        zIndex={1}
      >
        <IconButton
          icon={<EditIcon />}
          aria-label="Edit"
          variant="solid"
          colorScheme="blue"
          size="sm"
          as={Link}
          to={`edit/${shareText._id}`}
        />
        <IconButton
          icon={<DeleteIcon />}
          aria-label="Delete"
          variant="solid"
          colorScheme="red"
          size="sm"
          onClick={() => onDelete(shareText)}
        />
      </Stack>

      <Box position="absolute" bottom={4} left={1} right={4} p={3}>
        <Text fontSize="xl" fontWeight="bold" color="white" mb={1}>
          {truncateWords(shareText.title, 15)}
        </Text>
        {/* <Text color="gray.300" fontSize="md" noOfLines={2}>
          {truncateWords(shareText.text, 50)}
        </Text> */}
        <ReactMarkdown>{truncateWords(shareText.text, 150)}</ReactMarkdown>
      </Box>
    </Box>
  );
};

export default TextCard;
