import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { truncateWords } from "../util/uitil";
import { BiChevronDown, BiHelpCircle, BiLogOut } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import { SettingsIcon } from "@chakra-ui/icons";
import { signOut } from "../Redux/action/user.action";

export function SignedInMenu({ isAdmin }: { isAdmin: boolean }) {
  const toastPositions = useSelector((state: any) => state.toastPosition);
  const signUp = useSelector((state: { signUp: any }) => state.signUp);
  const { userInfo } = signUp;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const signOutHandler = () => {
    // dispatch<any>(resetAllStates());
    dispatch<any>(signOut(toastPositions, toast));
    navigate("/");
  };

  return (
    <>
      <Flex alignItems={"center"}>
        <Menu>
          <MenuButton
            // py={2}
            transition="all 0.3s"
            _focus={{ boxShadow: "none" }}
          >
            <HStack>
              <Avatar
                size={"sm"}
                name={userInfo?.userInfo?.username}
                src={
                  userInfo?.userInfo
                    ? userInfo.userInfo?.profilePic
                    : "https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                }
              />
              <VStack
                display={"flex"}
                // display={{ base: "none", md: "flex" }}
                alignItems="flex-start"
                spacing="1px"
                ml="2"
              >
                <Text
                  textTransform={"capitalize"}
                  fontWeight={"bold"}
                  fontSize="sm"
                >
                  {userInfo?.userInfo
                    ? truncateWords(userInfo?.userInfo?.username, 7)
                    : "NONE"}
                </Text>
                <Text fontSize="xs" color="gray.500">
                  {isAdmin ? "Admin" : "User"}
                </Text>
              </VStack>
              <Box display={{ base: "none", md: "flex" }}>
                <BiChevronDown />
              </Box>
            </HStack>
          </MenuButton>

          <MenuList
            zIndex={1111111}
            bg={useColorModeValue("white", "gray.700")}
            borderColor={useColorModeValue("gray.200", "gray.700")}
          >
            <MenuItem>
              <Link style={{ width: "100%" }} to="/v1/profile">
                <Icon as={FaUser} mr={2} />
                Profile
              </Link>
            </MenuItem>
            <MenuItem>
              <Link style={{ width: "100%" }} to="/v1/setting">
                <Icon as={SettingsIcon} mr={2} />
                Settings
              </Link>
            </MenuItem>
            <MenuItem>
              <Link style={{ width: "100%" }} to="/helps">
                <Icon as={BiHelpCircle} mr={2} />
                Help & Support
              </Link>
            </MenuItem>

            <MenuDivider
              borderColor={useColorModeValue("gray.300", "gray.400")}
            />

            <MenuItem
              onClick={signOutHandler}
              _hover={{
                bg: useColorModeValue("gray.100", "gray.800"),
              }}
              color={useColorModeValue("red.500", "red.300")}
            >
              <Icon as={BiLogOut} mr={2} />
              Sign out
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </>
  );
}
