import {
  Box,
  Code,
  Flex,
  Heading,
  IconButton,
  Image,
  Link,
  List,
  ListItem,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import NavBar from "../components/NavBar";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import SocialMedia from "../components/SocialMedia";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getActiveShareTextById } from "../Redux/action/text.actions";
import { Loading } from "../components/Components";
import ErrorCard from "../components/Common/ErrorCard";

const TextPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const toast = useToast();

  const _activeShareText = useSelector(
    (state: { activeShareText: any }) => state.activeShareText
  );
  const { loading, shareText, error } = _activeShareText;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    if (id) {
      dispatch<any>(getActiveShareTextById(id, toastPositions, toast));
    }
  }, [dispatch, id]);

  return (
    <Box
      // margin="1rem"
      height="100vh"
      // height="calc(93vh - 1rem)"
      // height="calc(100vh - 4rem)"
      // borderRadius={{ base: "30px", sm: "15px", md: "30px" }}
      position="relative"
      overflow={"hidden"}
      borderWidth={"1px"}
      borderColor={"teal"}
    >
      <Box pt="5rem" px={"10px"} overflow="auto" height={"100%"}>
        <NavBar backdropFilter={"blur(10px)"} />

        {!loading && !error ? (
          <ReactMarkdown
            children={shareText?.text}
            remarkPlugins={[remarkGfm]}
            components={{
              h1: ({ children }) => (
                <Heading as="h1" size="xl" mt={4} mb={2}>
                  {children}
                </Heading>
              ),
              h2: ({ children }) => (
                <Heading as="h2" size="lg" mt={4} mb={2}>
                  {children}
                </Heading>
              ),
              h3: ({ children }) => (
                <Heading as="h3" size="md" mt={4} mb={2}>
                  {children}
                </Heading>
              ),
              p: ({ children }) => (
                <Text mt={2} mb={2}>
                  {children}
                </Text>
              ),
              a: ({ href, children }) => (
                <Link href={href} color="teal.500" isExternal>
                  {children}
                </Link>
              ),
              ul: ({ children }) => (
                <List styleType="disc" pl={4} mt={2} mb={2}>
                  {children}
                </List>
              ),
              ol: ({ children }) => (
                <List styleType="decimal" pl={4} mt={2} mb={2}>
                  {children}
                </List>
              ),
              li: ({ children }) => <ListItem>{children}</ListItem>,

              code: ({ className, children }) => {
                const isInline = !className;
                return isInline ? (
                  <Code display="inline" p={1}>
                    {children}
                  </Code>
                ) : (
                  <Code display="block" whiteSpace="pre" p={2} my={2} w="100%">
                    {children}
                  </Code>
                );
              },
              img: ({ src, alt }) => (
                <>
                  {isLoading && (
                    <Box
                      // position="absolute"
                      height="150px"
                      width="150px"
                    >
                      <Spinner size="xl" color="white" />
                    </Box>
                  )}

                  <Image
                    onLoad={() => setIsLoading(false)}
                    display={isLoading ? "none" : "block"}
                    src={src}
                    alt={alt}
                    my={4}
                  />
                </>
              ),
            }}
          />
        ) : error ? (
          <Flex
            height="50vh"
            width="full"
            alignItems="center"
            justifyContent="center"
          >
            <Box width="200px">
              <ErrorCard message={error} />
            </Box>
          </Flex>
        ) : (
          <Loading />
        )}

        {/* social m */}
        <SocialMedia my={"3rem"} />
      </Box>
    </Box>
  );
};

export default TextPage;
