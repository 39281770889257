import { useDispatch, useSelector } from "react-redux";
import AuthForm from "../components/AuthForm";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { SignInAction } from "../Redux/action/user.action";

const SignInPage = () => {
  const toastPositions = useSelector((state: any) => state.toastPosition);

  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const submitHandler = async (e: any, formValues: any) => {
    e.preventDefault();
    // console.log(formValues);
    dispatch<any>(SignInAction(formValues, toastPositions, toast));
  };

  return (
    <>
      <AuthForm
        title="Sign In"
        description="Sign in now and explore your journey"
        fields={[
          {
            id: "email",
            label: "Email address",
            type: "email",
            isRequired: true,
          },
          {
            id: "password",
            label: "Password",
            type: "password",
            isRequired: true,
          },
        ]}
        submitHandler={submitHandler}
        alternateText="Don't have an account?"
        alternateLink="/sign-up"
        alternateLinkText="Create Now"
      />
    </>
  );
};

export default SignInPage;
