import { Badge, Box, IconButton, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { truncateWords } from "../../../util/uitil";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { BiCameraOff, BiImage, BiVideo, BiVideoOff } from "react-icons/bi";

interface PictureCardProps {
  sharePicture: any;
  onEdit: (id: string) => void;
  onDelete: (sharePicture: any) => void;
}

const PictureCard: React.FC<PictureCardProps> = ({
  sharePicture,
  onDelete,
  onEdit,
}) => {
  return (
    <Box
      backgroundSize={"cover !important"}
      background={
        sharePicture?.pictures?.length
          ? `linear-gradient(360deg, rgb(0 0 0 ), rgba(0, 0, 0, 0) 100%) ,center url(${sharePicture.pictures[0]})`
          : "gray"
      }
      minWidth="200px"
      minHeight="300px"
      position="relative"
      p={4}
      borderRadius="lg"
      boxShadow="lg"
    >
      <Badge
        backgroundColor={sharePicture.status ? "#4fd1c5" : "#d14f70"}
        position={"absolute"}
        right={"10px"}
        top={"10px"}
        zIndex={1}
        borderRadius="full"
        color={"black"}
        px="2"
      >
        {sharePicture?.status ? "Active" : "Deactive"}
      </Badge>

      <Box position="absolute" top="10px" left="10px" zIndex={1} color="white">
        {sharePicture?.pictures?.length ? (
          <Box display={"flex"} gap={"5px"}>
            <BiImage size="24px" />
            <Text fontWeight={"bold"}> {sharePicture?.pictures?.length} </Text>
          </Box>
        ) : (
          <BiCameraOff size="24px" />
        )}
      </Box>

      <Stack
        direction="column"
        spacing={2}
        position="absolute"
        bottom="30px"
        right="13px"
        zIndex={1}
      >
        <IconButton
          icon={<EditIcon />}
          aria-label="Edit"
          variant="solid"
          colorScheme="blue"
          size="sm"
          onClick={() => onEdit(sharePicture._id)}
        />
        <IconButton
          icon={<DeleteIcon />}
          aria-label="Delete"
          variant="solid"
          colorScheme="red"
          size="sm"
          onClick={() => onDelete(sharePicture)}
        />
      </Stack>

      <Box position="absolute" bottom={4} left={1} right={4} p={3}>
        <Text fontSize="xl" fontWeight="bold" color="white" mt={0} mb={1}>
          {truncateWords(sharePicture.title, 15)}
        </Text>
        <Text color="gray.300" fontSize="md" noOfLines={2}>
          {truncateWords(sharePicture.text, 50)}
        </Text>
      </Box>
    </Box>
  );
};

export default PictureCard;
