import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import RoleTablesRow from "./components/RoleTablesRow";
import { useDispatch, useSelector } from "react-redux";
import AddRoleForm from "./components/AddRoleForm";
import { IRole } from "../../../interface/role.interface";
import CommonTable from "../../../components/Common/CommonTable";
import {
  createRole,
  deleteRole,
  getAllRoles,
  updateRole,
} from "../../../Redux/action/role.action";

const RolesPage = () => {
  const [data, setData] = useState<IRole[]>([]); // set initial data
  const PAGE_SIZE = 6;
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState<IRole[]>([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const _createRole = useSelector(
    (state: { createRole: any }) => state.createRole
  );
  const { loading: createLoading, error: createError, role } = _createRole;

  const _getAllRoles = useSelector(
    (state: { getAllRoles: any }) => state.getAllRoles
  );
  const { loading: getRoleLoading, error, roles } = _getAllRoles;

  const _updateRole = useSelector(
    (state: { updateRole: any }) => state.updateRole
  );
  const {
    loading: updateLoading,
    error: updateError,
    role: roleUpdate,
  } = _updateRole;

  const _deleteRole = useSelector(
    (state: { deleteRole: any }) => state.deleteRole
  );
  const {
    loading: deleteLoading,
    error: deleteError,
    role: deleteMessage,
  } = _deleteRole;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    dispatch<any>(getAllRoles(toastPositions, toast));
    setLoading(true);
    // console.log(loading);
  }, [dispatch, deleteMessage, role, roleUpdate, toast]);

  useEffect(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    // console.log({ _getAllRoles });
    setData(roles);
    setFilteredData(
      data
        .filter((d) =>
          d.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        )
        .slice(startIndex, endIndex)
    );
    setLoading(false);

    if (searchTerm) {
      setData(filteredData);
    }
  }, [searchTerm, data, roles, page]);
  const [showModal, setShowModal] = useState(false);

  // Pagination logic...
  const handleAddButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFormSubmit = () => {
    // Handle form submission here
    setShowModal(false);
  };

  const handleAddRole = (rData: any) => {
    // console.log("Adding user:", rData);
    dispatch<any>(
      createRole(
        {
          name: rData.name,
          details: rData.details,
          permissions: rData.permissions,
          isDefault: rData.default,
          status: rData.status,
        },
        toastPositions,
        toast
      )
    );
  };

  return (
    <>
      <CommonTable
        title="Roles"
        color="transparent"
        caption={[
          "Name",
          "Description",
          "Permissions",
          "Is Default",
          "Status",
          "Actions",
        ]}
        tableData={data}
        pageSize={6}
        handleAddButtonClick={handleAddButtonClick}
        handleCloseModal={handleCloseModal}
        handleFormSubmit={handleFormSubmit}
        showModal={showModal}
        error={error}
        loading={loading}
        formComponent={
          <AddRoleForm closeModal={handleCloseModal} onSubmit={handleAddRole} />
        }
        renderRow={(role: IRole) => (
          <RoleTablesRow<IRole>
            data={role}
            onEdit={(editedRole: IRole) => {
              dispatch<any>(
                updateRole(
                  editedRole?._id,
                  {
                    name: editedRole?.name,
                    description: editedRole?.details,
                    permissions: editedRole?.permissions,
                    isDefault: editedRole?.isDefault,
                    status: editedRole?.status,
                  },
                  toastPositions,
                  toast
                )
              );
            }}
            onDelete={(deletedRole: IRole) => {
              dispatch<any>(
                deleteRole(deletedRole?._id, toastPositions, toast)
              );
            }}
          />
        )}
      />
    </>
  );
};

export default RolesPage;
