import React from "react";
import AuthForm from "../components/AuthForm";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { SignUp } from "../Redux/action/user.action";

const SignUpPage = () => {
  const toastPositions = useSelector((state: any) => state.toastPosition);

  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const submitHandler = async (e: any, formValues: any) => {
    e.preventDefault();
    // console.log(formValues);
    dispatch<any>(SignUp(formValues, toastPositions, toast));
  };
  return (
    <AuthForm
      title="Sign Up"
      description="Create an account to start your journey"
      fields={[
        { id: "username", label: "User Name", type: "text", isRequired: true },
        {
          id: "email",
          label: "Email address",
          type: "email",
          isRequired: true,
        },
        {
          id: "phone_no",
          label: "Phone Number",
          type: "number",
          isRequired: true,
        },
        {
          id: "password",
          label: "Password",
          type: "password",
          isRequired: true,
        },
        {
          id: "confirmPassword",
          label: "Confirm Password",
          type: "password",
          isRequired: true,
        },
      ]}
      submitHandler={submitHandler}
      alternateText="Already have an account?"
      alternateLink="/signin"
      alternateLinkText="Sign In"
    />
  );
};

export default SignUpPage;
