export interface User {
  // Define the shape of the user object
}

export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";
export const USER_SIGNOUT = "USER_SIGNOUT";

export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";
export const USER_SIGNUP_RESET = "USER_SIGNUP_RESET";

export const SET_USER_ROLE = "SET_USER_ROLE";

// export const GET_LOGGED_IN_USER_REQUEST = "GET_LOGGED_IN_USER_REQUEST";
// export const GET_LOGGED_IN_USER_SUCCESS = "GET_LOGGED_IN_USER_SUCCESS";
// export const GET_LOGGED_IN_USER_FAILURE = "GET_LOGGED_IN_USER_FAILURE";
export const GET_LOGGED_IN_USER_RESET = "GET_LOGGED_IN_USER_RESET";

export const RESET_GET_LOGGED_IN_USER_STATE = "RESET_GET_LOGGED_IN_USER_STATE";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_UPDATE_PASSWORD_REQUEST = "USER_UPDATE_PASSWORD_REQUEST";
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS";
export const USER_UPDATE_PASSWORD_FAIL = "USER_UPDATE_PASSWORD_FAIL";
export const USER_UPDATE_PASSWORD_RESET = "USER_UPDATE_PASSWORD_RESET";

export const USER_LOGOUT = "USER_LOGOUT";

export const RESET_ALL_STATES = "RESET_ALL_STATES";

export const DISABLE_USERS_REQUEST = "DISABLE_USERS_REQUEST";
export const DISABLE_USERS_SUCCESS = "DISABLE_USERS_SUCCESS";
export const DISABLE_USERS_FAILURE = "DISABLE_USERS_FAILURE";

export const ENABLE_USERS_REQUEST = "ENABLE_USERS_REQUEST";
export const ENABLE_USERS_SUCCESS = "ENABLE_USERS_SUCCESS";
export const ENABLE_USERS_FAILURE = "ENABLE_USERS_FAILURE";

// /////////////////////
export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const GET_USER_BY_ID_REQUEST = "GET_USER_BY_ID_REQUEST";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAIL = "GET_USER_BY_ID_FAIL";

export const GET_LOGGED_IN_USER_REQUEST = "GET_LOGGED_IN_USER_REQUEST";
export const GET_LOGGED_IN_USER_SUCCESS = "GET_LOGGED_IN_USER_SUCCESS";
export const GET_LOGGED_IN_USER_FAIL = "GET_LOGGED_IN_USER_FAIL";

export const EDIT_PERSONAL_ACCOUNT_REQUEST = "EDIT_PERSONAL_ACCOUNT_REQUEST";
export const EDIT_PERSONAL_ACCOUNT_SUCCESS = "EDIT_PERSONAL_ACCOUNT_SUCCESS";
export const EDIT_PERSONAL_ACCOUNT_FAIL = "EDIT_PERSONAL_ACCOUNT_FAIL";

export const CHANGE_PERSONAL_ACCOUNT_PASSWORD_REQUEST =
  "CHANGE_PERSONAL_ACCOUNT_PASSWORD_REQUEST";
export const CHANGE_PERSONAL_ACCOUNT_PASSWORD_SUCCESS =
  "CHANGE_PERSONAL_ACCOUNT_PASSWORD_SUCCESS";
export const CHANGE_PERSONAL_ACCOUNT_PASSWORD_FAIL =
  "CHANGE_PERSONAL_ACCOUNT_PASSWORD_FAIL";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const UPDATE_USER_ADMIN_REQUEST = "UPDATE_USER_ADMIN_REQUEST";
export const UPDATE_USER_ADMIN_SUCCESS = "UPDATE_USER_ADMIN_SUCCESS";
export const UPDATE_USER_ADMIN_FAIL = "UPDATE_USER_ADMIN_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
