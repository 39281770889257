import axios from "axios";
import { handleErrorMessage } from "../../interceptor/ErrorHandler";
import API_BASE_URL from "../../util/config";
import {
  GET_ACTIVE_SHARE_TEXT_REQUEST,
  GET_ACTIVE_SHARE_TEXT_SUCCESS,
  GET_ACTIVE_SHARE_TEXT_FAIL,
  GET_ALL_SHARE_TEXTS_REQUEST,
  GET_ALL_SHARE_TEXTS_SUCCESS,
  GET_ALL_SHARE_TEXTS_FAIL,
  GET_SHARE_TEXT_BY_ID_REQUEST,
  GET_SHARE_TEXT_BY_ID_SUCCESS,
  GET_SHARE_TEXT_BY_ID_FAIL,
  CREATE_SHARE_TEXT_REQUEST,
  CREATE_SHARE_TEXT_SUCCESS,
  CREATE_SHARE_TEXT_FAIL,
  UPDATE_SHARE_TEXT_REQUEST,
  UPDATE_SHARE_TEXT_SUCCESS,
  UPDATE_SHARE_TEXT_FAIL,
  DELETE_SHARE_TEXT_REQUEST,
  DELETE_SHARE_TEXT_SUCCESS,
  DELETE_SHARE_TEXT_FAIL,
} from "../constant/text.constatnt";
import { UseToastOptions } from "@chakra-ui/react";
import { getConfig } from "./common/action";

export const getActiveShareTextById =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ACTIVE_SHARE_TEXT_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}share/t/${id}`, config);

      dispatch({
        type: GET_ACTIVE_SHARE_TEXT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ACTIVE_SHARE_TEXT_FAIL
      );
    }
  };

export const getAllShareTexts =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ALL_SHARE_TEXTS_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}shareText`, config);

      dispatch({
        type: GET_ALL_SHARE_TEXTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ALL_SHARE_TEXTS_FAIL
      );
    }
  };

export const getShareTextById =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_SHARE_TEXT_BY_ID_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(
        `${API_BASE_URL}shareText/${id}`,
        config
      );

      dispatch({
        type: GET_SHARE_TEXT_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_SHARE_TEXT_BY_ID_FAIL
      );
    }
  };
export const createShareText =
  (
    // title: any,
    // text: any,
    // media: any,
    // captions: any,
    // status: any,
    formData: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: CREATE_SHARE_TEXT_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.post(
        `${API_BASE_URL}shareText`,
        // { title, text, media, captions, status },
        formData,
        config
      );
      dispatch({
        type: CREATE_SHARE_TEXT_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `Share text created successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        CREATE_SHARE_TEXT_FAIL
      );
    }
  };

export const updateShareText =
  (
    id: any,
    // title: any,
    // text: any,
    // media: any,
    // captions: any,
    // status: any,
    formData: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: UPDATE_SHARE_TEXT_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.put(
        `${API_BASE_URL}shareText/${id}`,
        // { title, text, media, captions, status },
        formData,
        config
      );
      dispatch({
        type: UPDATE_SHARE_TEXT_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `Share text updated successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        UPDATE_SHARE_TEXT_FAIL
      );
    }
  };

export const deleteShareText =
  (
    id: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: DELETE_SHARE_TEXT_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.delete(
        `${API_BASE_URL}shareText/${id}`,
        config
      );
      dispatch({
        type: DELETE_SHARE_TEXT_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `Share text deleted successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        DELETE_SHARE_TEXT_FAIL
      );
    }
  };
