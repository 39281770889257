import axios from "axios";
import { handleErrorMessage } from "../../interceptor/ErrorHandler";
import {
  GET_ACTIVE_SHARE_PICTURE_REQUEST,
  GET_ACTIVE_SHARE_PICTURE_SUCCESS,
  GET_ACTIVE_SHARE_PICTURE_FAIL,
  GET_ALL_SHARE_PICTURES_REQUEST,
  GET_ALL_SHARE_PICTURES_SUCCESS,
  GET_ALL_SHARE_PICTURES_FAIL,
  CREATE_SHARE_PICTURE_REQUEST,
  CREATE_SHARE_PICTURE_SUCCESS,
  CREATE_SHARE_PICTURE_FAIL,
  UPDATE_SHARE_PICTURE_REQUEST,
  UPDATE_SHARE_PICTURE_SUCCESS,
  UPDATE_SHARE_PICTURE_FAIL,
  DELETE_SHARE_PICTURE_REQUEST,
  DELETE_SHARE_PICTURE_SUCCESS,
  DELETE_SHARE_PICTURE_FAIL,
  GET_SHARE_PICTURE_REQUEST,
  GET_SHARE_PICTURE_FAIL,
  GET_SHARE_PICTURE_SUCCESS,
} from "../constant/picture.constant";
import { UseToastOptions } from "@chakra-ui/react";
import API_BASE_URL from "../../util/config";
import { getConfig, getConfigForFileUpload } from "./common/action";

export const getActiveSharePictureById =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ACTIVE_SHARE_PICTURE_REQUEST });

      const { data } = await axios.get(`${API_BASE_URL}share/p/${id}`);

      dispatch({
        type: GET_ACTIVE_SHARE_PICTURE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ACTIVE_SHARE_PICTURE_FAIL
      );
    }
  };

export const getAllSharePictures =
  (
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_ALL_SHARE_PICTURES_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(`${API_BASE_URL}sharepictures`, config);

      dispatch({
        type: GET_ALL_SHARE_PICTURES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_ALL_SHARE_PICTURES_FAIL
      );
    }
  };

export const getSharePictureById =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: GET_SHARE_PICTURE_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.get(
        `${API_BASE_URL}sharepictures/${id}`,
        config
      );

      dispatch({
        type: GET_SHARE_PICTURE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        GET_SHARE_PICTURE_FAIL
      );
    }
  };
export const createSharePicture =
  (
    sharePictureData: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: CREATE_SHARE_PICTURE_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfigForFileUpload(userInfo);

      const { data } = await axios.post(
        `${API_BASE_URL}sharepictures`,
        sharePictureData,
        config
      );
      // console.log({ sharePictureData, data });
      dispatch({
        type: CREATE_SHARE_PICTURE_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `Share picture created successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        CREATE_SHARE_PICTURE_FAIL
      );
    }
  };

export const updateSharePicture =
  (
    id: string,
    sharePictureData: any,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: UPDATE_SHARE_PICTURE_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfigForFileUpload(userInfo);

      const { data } = await axios.put(
        `${API_BASE_URL}sharepictures/${id}`,
        sharePictureData,
        config
      );

      dispatch({
        type: UPDATE_SHARE_PICTURE_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `Share picture updated successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        UPDATE_SHARE_PICTURE_FAIL
      );
    }
  };

export const deleteSharePicture =
  (
    id: string,
    toastPosition: any,
    toasts: (options: UseToastOptions | undefined) => void
  ) =>
  async (
    dispatch: (arg0: { type: string; payload?: any }) => void,
    getState: () => { signUp: { userInfo: any } }
  ) => {
    try {
      dispatch({ type: DELETE_SHARE_PICTURE_REQUEST });

      const {
        signUp: { userInfo },
      } = getState();
      const config = getConfig(userInfo);

      const { data } = await axios.delete(
        `${API_BASE_URL}sharepictures/${id}`,
        config
      );

      dispatch({
        type: DELETE_SHARE_PICTURE_SUCCESS,
        payload: data,
      });

      // Add toast notification
      toasts({
        title: `Share picture deleted successfully`,
        status: "success",
        duration: 3000,
        position: toastPosition ? toastPosition : "top-right",
        isClosable: true,
      });
    } catch (error) {
      handleErrorMessage(
        toasts,
        toastPosition,
        dispatch,
        error,
        DELETE_SHARE_PICTURE_FAIL
      );
    }
  };
