import React, { useState, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Td,
  useColorModeValue,
  useDisclosure,
  Grid,
  Textarea,
  Switch,
  Badge,
} from "@chakra-ui/react"; // Adjust the imports based on your actual UI library
import { truncateWords } from "../../../../util/uitil";

interface PermissionTablesRowProps<T> {
  data: T;
  onEdit: (editedData: T) => void;
  onDelete: (id: T) => void;
}

const PermissionTablesRow = <
  T extends { _id: string; name: string; details: string; status: boolean }
>({
  data,
  onEdit,
  onDelete,
}: PermissionTablesRowProps<T>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef<HTMLInputElement | null>(null);
  const finalRef = useRef<HTMLInputElement | null>(null);
  const textColor = useColorModeValue("gray.800", "white");
  const [editingData, setEditingData] = useState<T>(data);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const toastPositions = useSelector((state: any) => state.toastPosition);

  // const dispatch = useDispatch();
  // const toast = useToast()

  const handleEdit = () => {
    // Handle the edit functionality here
    onEdit(editingData);
    onClose();
  };

  const handleDelete = () => {
    // Close the delete modal and call the onDelete callback
    setShowDeleteModal(false);
    onDelete(data);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const isChecked = (e.target as HTMLInputElement).checked;
      setEditingData((prevData) => ({
        ...prevData,
        [name]: isChecked,
      }));
    } else {
      setEditingData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  return (
    <>
      {/* <Tr> */}

      <Td pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {data.name}
          </Text>
        </Flex>
      </Td>

      <Td pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {truncateWords(data.details, 45)}
          </Text>
        </Flex>
      </Td>

      <Td>
        <Badge
          bg={data.status === true ? "green.400" : "red.400"}
          color={data.status === true ? "white" : "white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {data.status ? "Active" : "InActive"}
        </Badge>
      </Td>

      <Td>
        <Grid gridAutoFlow={"column"} gap={"10px"}>
          <Button
            onClick={() => {
              setEditingData(data);
              onOpen();
            }}
            variant="outline"
            colorScheme="purple"
            py={"2px"}
          >
            Edit
          </Button>
          <Button
            onClick={() => setShowDeleteModal(true)}
            variant="outline"
            colorScheme="red"
            py={"2px"}
            px={"30px"}
          >
            <Text fontSize="md" fontWeight="bold" cursor="pointer">
              Delete
            </Text>
          </Button>
        </Grid>
      </Td>

      {/* edit modal */}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="md"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justify="space-between">
              <Flex align="center" gap="4">
                <Avatar name={editingData.name} src={editingData.name} />
                <Box>
                  <Heading size="sm">{editingData.name}</Heading>
                  {/* <Text fontSize="sm" color="gray.400" fontWeight="normal">{editingData.stock}</Text> */}
                </Box>
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                defaultValue={editingData.name}
                onChange={handleInputChange}
                name="name"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                defaultValue={editingData.details}
                onChange={handleInputChange}
                name="details"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Status</FormLabel>
              <Switch
                colorScheme="green"
                name="status"
                isChecked={editingData.status}
                onChange={handleInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="black"
              _hover={{ bg: "blackAlpha.600" }}
              color={"white"}
              mr={3}
              onClick={handleEdit}
            >
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* delete modal  */}
      <Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this product?</Text>
          </ModalBody>
          <ModalFooter gap={"10px"}>
            <Button colorScheme="red" onClick={handleDelete}>
              Delete
            </Button>
            <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PermissionTablesRow;
