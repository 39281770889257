import MainCarousel from "../components/MainCarousel";
import { Box } from "@chakra-ui/react";
import NavBar from "../components/NavBar";

const HomePage = () => {
  return (
    <Box
      position="relative"
      height="100vh"
      // height="calc(100vh - 0rem)"
      margin={"0rem"}
    >
      <NavBar />

      <MainCarousel />
    </Box>
  );
};

export default HomePage;
