import { useEffect, useState } from "react";
import AddUserForm from "./components/AddUserForm";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { User } from "../../../interface/user.interface";
import CommonTable from "../../../components/Common/CommonTable";
import UserTablesRow from "./components/UserTablesRow";
import {
  deleteUser,
  getAllUsers,
  updateUserAdmin,
} from "../../../Redux/action/user.action";

const UserPage = () => {
  const [data, setData] = useState<User[]>([]);
  const PAGE_SIZE = 6;
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const _allUsers = useSelector((state: { allUsers: any }) => state.allUsers);
  const { loading: loadingUser, error, users } = _allUsers;

  const _updateUserAdmin = useSelector(
    (state: { updateUserAdmin: any }) => state.updateUserAdmin
  );
  const {
    loading: editLoading,
    error: editError,
    user: editSuccess,
  } = _updateUserAdmin;

  const _deleteUser = useSelector(
    (state: { deleteUser: any }) => state.deleteUser
  );
  const {
    loading: deleteLoading,
    error: deleteError,
    message: successDelete,
  } = _deleteUser;

  // const _disableUsers = useSelector(
  //   (state: { disableUsers: any }) => state.disableUsers
  // );
  // const {
  //   loading: disableLoading,
  //   error: disableError,
  //   user: disableUser,
  // } = _disableUsers;

  // const _enableUsers = useSelector(
  //   (state: { enableUsers: any }) => state.enableUsers
  // );
  // const {
  //   loading: enableLoading,
  //   error: enableError,
  //   user: enableUser,
  // } = _enableUsers;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    dispatch<any>(getAllUsers(toastPositions, toast));
    setLoading(true);
    // console.log(loading);
  }, [dispatch, successDelete, searchTerm, editSuccess, toast]);

  useEffect(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;

    setData(users);
    // console.log({ _allUsers });
    setFilteredData(
      data
        .filter((d) =>
          d.username?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        )
        .slice(startIndex, endIndex)
    );
    setLoading(false);

    if (searchTerm) {
      setData(filteredData);
    }
  }, [searchTerm, data, users, page]);

  const handleAddUser = (userData: any) => {
    // console.log("Adding user:", userData);
  };
  const handleAddUsers = () => {
    // console.log("Adding user:");
  };
  const [showModal, setShowModal] = useState(false);

  const handleAddButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFormSubmit = () => {
    setShowModal(false);
  };

  return (
    <>
      <CommonTable
        title="User"
        color="transparent"
        caption={[
          "User Info",
          "Role",
          "Account Status",
          "Created-At",
          "Actions",
        ]}
        tableData={data}
        pageSize={6}
        handleAddButtonClick={handleAddButtonClick}
        handleCloseModal={handleCloseModal}
        handleFormSubmit={handleFormSubmit}
        showModal={showModal}
        loading={loadingUser}
        error={error}
        formComponent={
          <AddUserForm closeModal={handleCloseModal} onSubmit={handleAddUser} />
        }
        renderRow={(user: User) => (
          <UserTablesRow<User>
            data={user}
            onEdit={(editedUser: User) => {
              dispatch<any>(
                updateUserAdmin(
                  editedUser?._id,
                  {
                    username: editedUser.username,
                    email: editedUser.email,
                    Status: editedUser.Status,
                    role: editedUser.role,
                  },
                  toastPositions,
                  toast
                )
              );
            }}
            onDelete={(deletedUser: User) => {
              dispatch<any>(
                deleteUser(deletedUser?._id, toastPositions, toast)
              );
            }}
          />
        )}
      />
    </>
  );
};

export default UserPage;
