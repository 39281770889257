import {
  Button,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiHome } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Loading, NoData } from "../../../components/Components";
import PageHeader from "../../../components/PageHeader";
import {
  deleteLanding,
  getAllLanding,
} from "../../../Redux/action/landing.action";
import AddEditLandingForm from "./AddEditLandingForm";
import LandingCard from "./LandingCard";

const LandingAdminPage = () => {
  const PAGE_SIZE = 6;
  const [data, setData] = useState<any[]>([]); // set initial data
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [landingToDelete, setLandingToDelete] = useState<any>(null);
  const [editLandingId, setEditLandingId] = useState<string | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();
  const toast = useToast();

  const _getAllLanding = useSelector(
    (state: { getAllLanding: any }) => state.getAllLanding
  );
  const { loading: landingLoading, landings, error } = _getAllLanding;

  // create
  const _createLanding = useSelector(
    (state: { createLanding: any }) => state.createLanding
  );
  const {
    loading: createlandingLoading,
    landing: createLanding,
    error: createError,
  } = _createLanding;

  // update
  const _updateLanding = useSelector(
    (state: { updateLanding: any }) => state.updateLanding
  );
  const {
    loading: updatelandingLoading,
    landing: updateLanding,
    error: updateError,
  } = _updateLanding;

  const _deleteLanding = useSelector(
    (state: { deleteLanding: any }) => state.deleteLanding
  );
  const {
    loading: landingDeleteLoading,
    landing: DeleteLanding,
    error: deleteLandingError,
  } = _deleteLanding;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleEdit = (id: string) => {
    setEditLandingId(id);
    onOpen();
  };

  const handleAdd = () => {
    setEditLandingId(null);
    onOpen();
  };

  const handleDelete = (landing: any) => {
    setLandingToDelete(landing);
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    // Perform the delete action here
    if (landingToDelete) {
      dispatch<any>(
        deleteLanding(landingToDelete._id, toastPositions, toast)
      ).then(() => {
        setTimeout(() => {
          dispatch<any>(getAllLanding(toastPositions, toast));
        }, 200);
      });
    }
    // Close the delete confirmation modal
    setDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    dispatch<any>(getAllLanding(toastPositions, toast));
    // console.log("er");
    setLoading(true);
  }, [dispatch, createLanding, updateLanding, toast]);

  useEffect(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;

    setData(landings);
    setFilteredData(
      data
        ?.filter((d) =>
          d.title?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice(startIndex, endIndex)
    );
    setLoading(false);

    // console.log({ data, filteredData, landings, toastPositions });
    landingLoading || updatelandingLoading || createlandingLoading
      ? setLoading(true)
      : setLoading(false);
  }, [landings, data, landingLoading, searchTerm, page]);

  return (
    <>
      <PageHeader
        onSearchChange={handleSearchChange}
        onButtonClick={handleAdd}
        searchTerm={searchTerm}
        listLength={data?.length}
        name="Landing"
        button={true}
      />

      {loading && <Loading />}

      {!loading && filteredData?.length > 0 ? (
        <>
          <Grid templateColumns="repeat(auto-fill, minmax(230px, 1fr))" gap={6}>
            {filteredData?.map((landing) => (
              <LandingCard
                key={landing._id}
                landing={landing}
                onDelete={handleDelete}
                onEdit={handleEdit}
              />
            ))}
          </Grid>

          {data?.length > PAGE_SIZE && (
            <HStack mt={6}>
              {Array.from(
                Array(Math.ceil(data?.length / PAGE_SIZE)),
                (_, i) => i + 1
              ).map((p) => (
                <Button
                  key={p}
                  variant={page === p ? "solid" : "outline"}
                  size="sm"
                  onClick={() => handlePageChange(p)}
                >
                  {p}
                </Button>
              ))}
            </HStack>
          )}
        </>
      ) : (
        !loading && <NoData icon={BiHome} />
      )}

      <Modal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editLandingId ? "Edit Landing" : "Add Landing"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddEditLandingForm
              isEdit={!!editLandingId}
              landingId={editLandingId}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* delete */}
      <Modal
        isOpen={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this product?</Text>
          </ModalBody>
          <ModalFooter gap={"10px"}>
            <Button colorScheme="red" onClick={handleConfirmDelete}>
              Delete
            </Button>
            <Button onClick={() => setDeleteConfirmationOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default LandingAdminPage;
