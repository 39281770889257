import React, { useState } from "react";
import {
   Button,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  VStack,
} from "@chakra-ui/react";

interface FormData {
  name: string;
  email: string;
}

interface AddUserFormProps {
  onSubmit: (data: FormData) => void;
  closeModal: () => void;
}

const AddUserForm: React.FC<AddUserFormProps> = ({ onSubmit, closeModal }) => {
  const [formData, setFormData] = useState<FormData>({ name: "", email: "" });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData({ name: "", email: "" });
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </FormControl>
        <ModalFooter>
          <Button mr={4} type="submit" colorScheme="green">
            Add User
          </Button>
          <Button onClick={closeModal}>Close</Button>
        </ModalFooter>
      </VStack>
    </form>
  );
};

export default AddUserForm;
