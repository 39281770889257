import {
  Box,
  Text,
  Flex,
  Avatar,
  useColorModeValue,
  Button,
  Badge,
} from "@chakra-ui/react";

const WelcomeMessage = ({ userInfo, isAdmin }: any) => {
  const lightBgColor = useColorModeValue("gray.100", "gray.700");

  return (
    <>
      <Box bg={lightBgColor} p={4} borderRadius="md" boxShadow="md">
        <Flex position={"relative"} align="center" mb={4}>
          <Avatar
            size="md"
            name={userInfo?.username}
            src={userInfo?.profilePic}
            mr={3}
          />
          <Text
            textTransform={"capitalize"}
            fontSize="30px"
            fontWeight="bold"
            bgGradient="linear(to-r, teal.500, #8d22f0)"
            bgClip="text"
          >
            Welcome, {userInfo?.username}!
          </Text>

          {!isAdmin ? null : (
            <Badge
              position="absolute"
              top={2}
              right={2}
              colorScheme="red"
              fontSize="sm"
              borderRadius="md"
              px={2}
            >
              Admin
            </Badge>
          )}
        </Flex>

        {/* <Text color={textColor}>
          Thank you for using our Portfolio Managment API. We hope you have a
          great experience!
        </Text> */}
        <Button width="unset" colorScheme="teal" children={"Go to Profile"} />
      </Box>
    </>
  );
};

export default WelcomeMessage;
