import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  Link as ChakraLink,
  Center,
  useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Blur } from "./SignInSignUpLayout";
import { useDispatch, useSelector } from "react-redux";

interface AuthFormProps {
  title: string;
  description: string;
  fields: { id: string; label: string; type: string; isRequired?: boolean }[];
  submitHandler: (e: React.FormEvent, formValues: any) => void;
  alternateText: string;
  alternateLink: string;
  alternateLinkText: string;
}

const AuthForm: React.FC<AuthFormProps> = ({
  title,
  description,
  fields,
  submitHandler,
  alternateText,
  alternateLink,
  alternateLinkText,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const toastPositions = useSelector((state: any) => state.toastPosition);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const signIn = useSelector((state: { signIn: any }) => state.signIn);
  const { loading, error, userInfo } = signIn;

  const signUp = useSelector((state: { signUp: any }) => state.signUp);
  const {
    loading: signUpLoading,
    error: signUpError,
    userInfo: signUpInfo,
  } = signUp;

  // console.log(signIn, signUp);

  useEffect(() => {
    if (errorMessage) {
      toast({
        title: errorMessage,
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    }
    if (userInfo?.userInfo) {
      navigate("/v1");
    }
  }, [
    navigate,
    userInfo,
    errorMessage,
    loading,
    signUpInfo,
    signUpLoading,
    toast,
  ]);

  const forBg = useColorModeValue("whiteAlpha.600", "blackAlpha.100");

  const handleChange = (id: string, value: string) => {
    setFormValues({ ...formValues, [id]: value });
    // console.log(formValues);
  };

  const validateForm = () => {
    for (let field of fields) {
      if (field.isRequired && !formValues[field.id]) {
        setErrorMessage(`${field.label} is required`);
        return false;
      }
    }
    return true;
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      submitHandler(e, formValues);
    }
    // console.log(formValues);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <Box position="relative">
      <Container as={Center} maxW="6xl" py={10}>
        <Stack
          bg={useColorModeValue("whiteAlpha.600", "blackAlpha.600")}
          rounded="xl"
          borderWidth="1px"
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
          zIndex={11}
          textAlign="center"
        >
          <Stack spacing={4}>
            <Heading
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              {title}{" "}
              <Text as="span" bg="teal.400" bgClip="text">
                !
              </Text>
            </Heading>
            <Text color="gray.500" fontSize={{ base: "sm", sm: "md" }}>
              {description}{" "}
              <Text as="span" bg="teal" bgClip="text">
                !
              </Text>
            </Text>
          </Stack>

          <Box pt="2 !important" p={8}>
            <Stack spacing={4} as="form" onSubmit={onSubmit}>
              {fields.map((field) => (
                <FormControl
                  key={field.id}
                  id={field.id}
                  isRequired={field.isRequired}
                >
                  <FormLabel>{field.label}</FormLabel>
                  <InputGroup>
                    <Input
                      type={
                        field.type === "password" && showPassword
                          ? "text"
                          : field.type
                      }
                      value={formValues[field.id] || ""}
                      bg={forBg}
                      onChange={(e) => handleChange(field.id, e.target.value)}
                      isInvalid={errorMessage === `${field.label} is required`}
                    />
                    {field.type === "password" && (
                      <InputRightElement h="full">
                        <Button
                          variant="ghost"
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    )}
                  </InputGroup>
                </FormControl>
              ))}
              <Stack spacing={10}>
                <Button colorScheme="teal" isLoading={isLoading} type="submit">
                  {title}
                </Button>
              </Stack>
              <Text color="gray.500" fontSize={{ base: "sm", sm: "md" }}>
                {alternateText}{" "}
                <ChakraLink as={RouterLink} to={alternateLink} color="teal">
                  {alternateLinkText}
                </ChakraLink>{" "}
                ✌️
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Container>

      {/* bg */}
      <Blur
        position={"fixed"}
        top={"10%"}
        left={"10%"}
        style={{ filter: "blur(70px)" }}
        transform={"scale(1)"}
      />
    </Box>
  );
};

export default AuthForm;
