import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { FaPlay, FaPause } from "react-icons/fa";
import { formatTime, truncateWords } from "../util/uitil";
import NavBar from "../components/NavBar";
import SocialMedia from "../components/SocialMedia";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getActiveShareVideoById } from "../Redux/action/video.action";
import { Loading } from "../components/Components";
import ErrorCard from "../components/Common/ErrorCard";

const VideoPage: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [seeMore, setSeeMore] = useState(true);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const toast = useToast();

  const _activeShareVideoById = useSelector(
    (state: { activeShareVideoById: any }) => state.activeShareVideoById
  );
  const { loading, shareVideo, error } = _activeShareVideoById;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    if (id) {
      dispatch<any>(getActiveShareVideoById(id, toastPositions, toast));
    }
  }, [dispatch, id]);

  const handleSeeMore = () => {
    setSeeMore(!seeMore);
  };

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
      setIsLoading(false);
    }
  };

  const handleSliderChange = (value: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = value;
      setCurrentTime(value);
    }
  };

  useEffect(() => {
    const handleBuffering = () => {
      setIsLoading(true);
    };

    const handlePlaying = () => {
      setIsLoading(false);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("waiting", handleBuffering);
      videoRef.current.addEventListener("playing", handlePlaying);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("waiting", handleBuffering);
        videoRef.current.removeEventListener("playing", handlePlaying);
      }
    };
  }, []);

  return (
    <Box
      // margin="1rem"
      height="100vh"
      // height="calc(93vh - 1rem)"
      // borderRadius={{ base: "30px", sm: "15px", md: "30px" }}
      // overflow="hidden"
      position="relative"
    >
      <Box
        position="relative"
        height="100%"
        width={"full"}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <NavBar />
        {!loading && !error ? (
          <Box>
            <video
              ref={videoRef}
              //   src="https://www.shutterstock.com/shutterstock/videos/3444632995/preview/stock-footage-happy-birthday-to-you-animated-text-video-k-animation-motion-graphic-on-white-background-vertical.webm"
              // src="https://www.shutterstock.com/shutterstock/videos/3445222723/preview/stock-footage-abstract-background-with-red-blue-neon-lines-neon-space-time-warp-with-glowing-waves-speed-and.webm"
              //   src="https://static.videezy.com/system/protected/files/000/021/600/Origami_Pig_Time_Lapse_2078.mp4" // Local video file
              src={shareVideo?.video}
              width="100%"
              height="100%"
              onClick={handlePlayPause}
              onTimeUpdate={handleTimeUpdate}
              onLoadedMetadata={handleLoadedMetadata}
              style={{ objectFit: "cover" }}
            ></video>

            {/* Custom Controllers */}
            {!isPlaying && (
              <IconButton
                icon={
                  <FaPlay
                    strokeWidth={"2px"}
                    stroke="#000"
                    fontSize={"45px"}
                    color="white"
                  />
                }
                colorScheme="teal"
                position="absolute"
                width={"80px"}
                pl={"6px"}
                height={"80px"}
                borderWidth={"1px"}
                borderRadius={"100px"}
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                onClick={handlePlayPause}
                zIndex="2"
                aria-label={""}
              />
            )}

            {/* Loading Spinner */}
            {isLoading && (
              <IconButton
                icon={<Spinner size="xl" color="white" />}
                colorScheme="whiteAlpha"
                background={"transparent"}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                onClick={handlePlayPause}
                zIndex="2"
                aria-label={""}
              />
            )}

            {isPlaying && !isLoading && (
              <IconButton
                icon={
                  <FaPause
                    strokeWidth={"2px"}
                    stroke="#000"
                    // fontSize={"45px"}
                    color="white"
                  />
                }
                colorScheme="teal"
                position="absolute"
                width={"40px"}
                height={"40px"}
                borderRadius={"100px"}
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                onClick={handlePlayPause}
                zIndex="2"
                aria-label={""}
              />
            )}

            <Box
              p={"6rem 1rem 1rem 1rem "}
              position="absolute"
              bottom="0rem"
              left="0"
              right="0"
              background={"linear-gradient(0deg, black 0%, transparent 99%)"}
            >
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                zIndex="2"
                width="100%"
                color="white"
              >
                {/* Text and Timestamp Adjuster */}
                <Box mb="0.5rem">
                  <Text fontWeight="bold" fontSize="25px">
                    {shareVideo?.title}
                  </Text>
                  <Flex
                    width={"100%"}
                    align={"center"}
                    justify={"space-between"}
                  >
                    <Text
                      maxHeight={"60vh"}
                      overflowY={"auto"}
                      width="calc(100% - 100px)"
                      fontSize="16px"
                      sx={{
                        // Custom scrollbar styles
                        "&::-webkit-scrollbar": {
                          borderRadius: "8px",
                          width: "8px",
                        },
                        "&::-webkit-scrollbar-track": {
                          borderRadius: "8px",
                          background: "#f1f1f1",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#888",
                          borderRadius: "8px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          borderRadius: "8px",
                          background: "#555",
                        },
                      }}
                    >
                      {truncateWords(
                        shareVideo?.text,
                        seeMore ? 130 : 200000000
                      )}
                    </Text>
                    <Text
                      //   width={"100px"}
                      onClick={handleSeeMore}
                      color={"blue.400"}
                      cursor={"pointer"}
                      as={"a"}
                    >
                      See {!seeMore ? "Less" : "More"}
                    </Text>
                  </Flex>
                  <Text color="teal" fontSize="sm">
                    {shareVideo?.caption}
                  </Text>
                </Box>

                {/* Timestamp Slider */}

                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Text fontSize="xs">{formatTime(currentTime)}</Text>
                  <Slider
                    aria-label="slider-ex-1"
                    value={currentTime}
                    max={duration}
                    onChange={handleSliderChange}
                    width="100%"
                    mx={"10px"}
                    colorScheme="teal"
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                  <Text fontSize="xs">
                    {formatTime(duration - currentTime)}
                  </Text>
                </Flex>
              </Flex>

              {/* social m */}
              <SocialMedia mt={"10px"} width={"100%"} color={"white"} />
            </Box>
          </Box>
        ) : error ? (
          <Flex
            height="50vh"
            width="full"
            alignItems="center"
            justifyContent="center"
          >
            <Box width="200px">
              <ErrorCard message={error} />
            </Box>
          </Flex>
        ) : (
          <Loading />
        )}
      </Box>
    </Box>
  );
};

export default VideoPage;
