import { Flex, FlexProps, IconButton, Text, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { getAllActiveSocialLinks } from "../Redux/action/socialLinks.action";
import { useDispatch, useSelector } from "react-redux";
import { getIconComponent } from "../Pages/Admin/SocialLinks/SocialAdminPage";

const SocialMedia: React.FC<FlexProps> = (props) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const _allActiveSocialLinks = useSelector(
    (state: { allActiveSocialLinks: any }) => state.allActiveSocialLinks
  );
  const { loading: aboutUsLoading, socialLinks, error } = _allActiveSocialLinks;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    dispatch<any>(getAllActiveSocialLinks(toastPositions, toast));
    // console.log("er");
  }, [dispatch, toast]);

  return (
    <>
      {!error && !aboutUsLoading && (
        <Flex
          {...props}
          width={"100%"}
          flexDir={"column"}
          alignItems={"center"}
        >
          <Text fontSize={"18px"} mb={"15px"}>
            Join Maki Gifts
          </Text>

          <Flex justifyContent={"center"}>
            {socialLinks?.map((socialLink: any, index: any) => (
              <IconButton
                as={"a"}
                target="_blank"
                href={socialLink?.link}
                aria-label="Facebook"
                icon={getIconComponent(socialLink?.icon, socialLink?.color)}
                variant="solid"
                isRound
                marginX="0.5rem"
                colorScheme={socialLink?.color}
              />
            ))}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default SocialMedia;
