import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Grid,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading, NoData } from "../../../components/Components";
import PageHeader from "../../../components/PageHeader";
import {
  deleteSocialLinks,
  getAllSocialLinks,
} from "../../../Redux/action/socialLinks.action";
import { FaGooglePlus } from "react-icons/fa";
import AddEditSocialForm from "./AddEditSocialForm";
import "./iconStyle.css";

export const getIconComponent = (svgString: string, color: string) => {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: svgString }}
      color={color}
      height="40px"
      width="40px"
      className="iconBox"
    />
  );
};

const SocialAdminPage = () => {
  const PAGE_SIZE = 6;
  const [data, setData] = useState<any[]>([]); // set initial data
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [socialToDelete, setSocialToDelete] = useState<any>(null);
  const [editSocialId, setEditSocialId] = useState<string | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();
  const toast = useToast();

  const _allSocialLinks = useSelector(
    (state: { allSocialLinks: any }) => state.allSocialLinks
  );
  const { loading: SocialLoading, socialLinks, error } = _allSocialLinks;

  const _createSocialLinks = useSelector(
    (state: { createSocialLinks: any }) => state.createSocialLinks
  );
  const { Social: createSocialLink } = _createSocialLinks;

  const _updateSocialLinks = useSelector(
    (state: { updateSocialLinks: any }) => state.updateSocialLinks
  );
  const { Social: updateSocialLink } = _updateSocialLinks;

  const _deleteSocialLinks = useSelector(
    (state: { deleteSocialLinks: any }) => state.deleteSocialLinks
  );
  const { Social: deleteSocialLink, error: deleteSocialLinksError } =
    _deleteSocialLinks;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleEdit = (id: string) => {
    setEditSocialId(id);
    onOpen();
  };

  const handleAdd = () => {
    setEditSocialId(null);
    onOpen();
  };

  const handleDelete = (social: any) => {
    setSocialToDelete(social);
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    // Perform the delete action here
    // console.log(socialToDelete);
    if (socialToDelete) {
      dispatch<any>(
        deleteSocialLinks(socialToDelete._id, toastPositions, toast)
      ).then(() => {
        setTimeout(() => {
          dispatch<any>(getAllSocialLinks(toastPositions, toast));
        }, 200);
      });
    }
    // Close the delete confirmation modal
    setDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    dispatch<any>(getAllSocialLinks(toastPositions, toast));
    // console.log("er");
    setLoading(true);
  }, [dispatch, createSocialLink, updateSocialLink, deleteSocialLink, toast]);

  useEffect(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;

    setData(socialLinks);
    setFilteredData(
      data
        ?.filter((d) =>
          d.name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice(startIndex, endIndex)
    );
    setLoading(false);

    // console.log({ data, filteredData, socialLinks, toastPositions });
    SocialLoading ? setLoading(true) : setLoading(false);
  }, [socialLinks, data, SocialLoading, searchTerm, page]);

  const backgroundImg = useColorModeValue("gray.100", "gray.700");
  const backgroundImgHover = useColorModeValue("gray.200", "gray.600");

  return (
    <>
      <PageHeader
        onSearchChange={handleSearchChange}
        onButtonClick={handleAdd}
        searchTerm={searchTerm}
        listLength={data?.length}
        name="Social Links"
        button={true}
      />

      {loading && <Loading />}

      {!loading && filteredData?.length > 0 ? (
        <>
          <Grid templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={6}>
            {filteredData?.map((socialLink, index) => (
              <VStack
                key={index}
                width="150px"
                justifyContent="center"
                alignItems="center"
                borderRadius="md"
                bg={backgroundImg}
                _hover={{ bg: backgroundImgHover }}
                m={4}
                p={4}
                boxShadow="md"
                borderWidth={"1px"}
                borderColor={socialLink?.color}
                as={Box}
                position={"relative"}
              >
                <Badge
                  backgroundColor={socialLink.status ? "#4fd1c5" : "#d14f70"}
                  position={"absolute"}
                  right={"10px"}
                  top={"10px"}
                  zIndex={1}
                  borderRadius="full"
                  color={"black"}
                  px="2"
                >
                  {socialLink.status ? "Active" : "Deactive"}
                </Badge>
                {getIconComponent(socialLink?.icon, socialLink?.color)}
                <Text fontSize="md" fontWeight="bold" mb={2}>
                  {socialLink?.name}
                </Text>
                <Stack direction="row" spacing={4}>
                  <IconButton
                    icon={<EditIcon />}
                    aria-label="Edit"
                    variant="outline"
                    colorScheme="blue"
                    size="sm"
                    onClick={() => handleEdit(socialLink?._id)}
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    aria-label="Delete"
                    variant="outline"
                    colorScheme="red"
                    size="sm"
                    onClick={() => handleDelete(socialLink)}
                  />
                </Stack>
              </VStack>
            ))}
          </Grid>

          {data?.length > PAGE_SIZE && (
            <HStack mt={6}>
              {Array.from(
                Array(Math.ceil(data?.length / PAGE_SIZE)),
                (_, i) => i + 1
              ).map((p) => (
                <Button
                  key={p}
                  variant={page === p ? "solid" : "outline"}
                  size="sm"
                  onClick={() => handlePageChange(p)}
                >
                  {p}
                </Button>
              ))}
            </HStack>
          )}
        </>
      ) : (
        !loading && <NoData icon={FaGooglePlus} />
      )}

      <Modal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editSocialId ? "Edit Social" : "Add Social"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddEditSocialForm
              isEdit={!!editSocialId}
              socialId={editSocialId}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* delete */}
      <Modal
        isOpen={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Pictures</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this Pictures?</Text>
          </ModalBody>
          <ModalFooter gap={"10px"}>
            <Button colorScheme="red" onClick={handleConfirmDelete}>
              Delete
            </Button>
            <Button onClick={() => setDeleteConfirmationOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default SocialAdminPage;
