import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  ModalBody,
  ModalFooter,
  Switch,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { IPermission } from "../../../../interface/permission.interface";
import { getActivePermissions } from "../../../../Redux/action/permission.action";
import EmptyMessage from "../../../../components/Common/EmptyMessage";
import ErrorCard from "../../../../components/Common/ErrorCard";
import { Loading } from "../../../../components/Components";

interface FormData {
  name: string;
  details: string;
  permissions: any[];
  default?: boolean;
  status?: boolean;
}

interface AddRoleFormProps {
  onSubmit: (data: FormData) => void;
  closeModal: () => void;
}

const AddRoleForm: React.FC<AddRoleFormProps> = ({ onSubmit, closeModal }) => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    details: "",
    permissions: [],
    default: false,
    status: false,
  });
  const [data, setData] = useState<IPermission[]>([]); // set initial data
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const _getActivePermissions = useSelector(
    (state: { getActivePermissions: any }) => state.getActivePermissions
  );
  const {
    loading: loadingPermissions,
    error,
    permissions,
  } = _getActivePermissions;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    dispatch<any>(getActivePermissions(toastPositions, toast));
    setLoading(true);
    // console.log(loading);
  }, [dispatch, toast]);

  useEffect(() => {
    setData(permissions);
    !loadingPermissions && setLoading(false);
  }, [permissions, data]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleDefaultChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    if (type === "checkbox") {
      const isChecked = (e.target as HTMLInputElement).checked;
      setFormData((prevData) => ({
        ...prevData,
        [name]: isChecked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handlePermissionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        permissions: [
          ...prevData.permissions,
          { _id: value, name: "", details: "", status: true },
        ], // Add permission ID to array
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        permissions: prevData.permissions.filter(
          (permission: any) => permission._id !== value
        ), // Remove permission ID from array
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    // console.log(formData);
    e.preventDefault();
    onSubmit(formData);
    setFormData({ name: "", details: "", permissions: [], default: false });
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack overflow={"auto"} spacing={4} align="stretch">
        <ModalBody>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name="name"
              isRequired={true}
              value={formData.name}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input
              type="text"
              isRequired={true}
              name="details"
              value={formData.details}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Is Default</FormLabel>
            <Switch
              colorScheme="green"
              name="default"
              isChecked={formData.default}
              onChange={handleDefaultChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Status</FormLabel>
            <Switch
              colorScheme="green"
              name="status"
              isChecked={formData.status}
              onChange={handleDefaultChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Permissions</FormLabel>
            <Grid margin={"5px"} gap={"10px"}>
              {!loading &&
                !error &&
                data?.length &&
                data.map((permission: IPermission) => (
                  <Checkbox
                    key={permission._id}
                    name={"permissions"}
                    value={permission._id}
                    onChange={handlePermissionChange}
                    // isChecked={editingData.permissions.includes(permission.id)}
                  >
                    <Flex flexDir={"column"}>
                      <Text textTransform={"uppercase"} fontWeight={600} mr={4}>
                        {" "}
                        {permission.name}
                      </Text>
                      <Text color={"gray.400"}> {permission.details}</Text>
                    </Flex>
                  </Checkbox>
                ))}
              {!error && !loading && (!data || data.length === 0) && (
                <Flex
                  height={"50vh"}
                  width={"full"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box width={"200px"}>
                    <EmptyMessage message={`No Permissions yet`} />
                  </Box>
                </Flex>
              )}
              {error && !loading && (
                <Flex
                  height={"50vh"}
                  width={"full"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box width={"200px"}>
                    <ErrorCard message={error} />
                  </Box>
                </Flex>
              )}
              {!error && loading && (
                <Flex
                  height={"50vh"}
                  width={"full"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box width={"200px"}>
                    <Loading />
                  </Box>
                </Flex>
              )}
            </Grid>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button mr={4} type="submit" colorScheme="green">
            Add Role
          </Button>
          <Button onClick={closeModal}>Close</Button>
        </ModalFooter>
      </VStack>
    </form>
  );
};

export default AddRoleForm;
