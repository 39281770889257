import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import {
  useToast,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Textarea,
  Flex,
  Button,
  Switch,
  ModalFooter,
  Text,
  Box,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../../components/Components";
import {
  createLanding,
  getAllLanding,
  getLandingById,
  updateLanding,
} from "../../../Redux/action/landing.action";

interface AddEditLandingFormProps {
  isEdit: boolean;
  landingId: string | null;
  onClose: () => void;
}

const AddEditLandingForm: React.FC<AddEditLandingFormProps> = ({
  isEdit,
  landingId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [formData, setFormData] = useState({
    title: "",
    subTitle: "",
    details: "",
    media: null as File | null,
    link: "",
    status: false,
  });
  const [mediaPreview, setMediaPreview] = useState<string | null>(null);
  const _getLandingById = useSelector(
    (state: { getLandingById: any }) => state.getLandingById
  );
  const { loading: landingLoading, landing, error } = _getLandingById;

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    if (isEdit && landingId) {
      dispatch<any>(getLandingById(landingId, toastPositions, toast));
    }
  }, [dispatch, landingId, isEdit]);

  useEffect(() => {
    if (isEdit && landing) {
      setFormData({
        title: landing.title,
        subTitle: landing.subTitle,
        details: landing.details,
        media: null,
        link: landing.link,
        status: landing.status,
      });
      setMediaPreview(landing.media);
    }
  }, [landing, isEdit]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const newPreview = URL.createObjectURL(file);
      setFormData((prevData) => ({ ...prevData, media: file }));
      setMediaPreview(newPreview);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const form = new FormData();
    form.append("title", formData.title);
    form.append("subTitle", formData.subTitle);
    form.append("details", formData.details);
    if (formData.media) {
      form.append("media", formData.media);
    }
    form.append("link", formData.link);
    form.append("status", JSON.stringify(formData.status));

    if (isEdit) {
      dispatch<any>(
        updateLanding(landingId ? landingId : "", form, toastPositions, toast)
      );
    } else {
      dispatch<any>(createLanding(form, toastPositions, toast));
    }
    // dispatch<any>(getAllLanding(toastPositions, toast));

    onClose();
  };

  const handleDropClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <form onSubmit={handleSubmit}>
      {landingLoading && <Loading />}

      <Stack spacing={5}>
        <FormControl>
          <FormLabel mb={0} fontSize="lg" htmlFor="title">
            Title
          </FormLabel>
          <FormHelperText mb={2} mt={0} id="title-helptext">
            Add title for this doc
          </FormHelperText>
          <Input
            id="title"
            name="title"
            placeholder="add title here"
            value={formData.title}
            onChange={handleInputChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel mb={0} fontSize="lg" htmlFor="subTitle">
            Subtitle
          </FormLabel>
          <FormHelperText mb={2} mt={0} id="subTitle-helptext">
            Add subtitle for this doc
          </FormHelperText>
          <Input
            id="subTitle"
            name="subTitle"
            placeholder="add subtitle here"
            value={formData.subTitle}
            onChange={handleInputChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel mb={0} fontSize="lg" htmlFor="details">
            Details
          </FormLabel>
          <FormHelperText mb={2} mt={0} id="details-helptext">
            Add detailed description
          </FormHelperText>
          <Textarea
            id="details"
            name="details"
            placeholder="add details here"
            value={formData.details}
            onChange={handleInputChange}
            rows={3}
            aria-describedby="details-helptext"
          />
        </FormControl>

        <FormControl>
          <FormLabel mb={0} fontSize="lg" htmlFor="link">
            Link
          </FormLabel>
          <FormHelperText mb={2} mt={0} id="link-helptext">
            Add link for this doc
          </FormHelperText>
          <Input
            id="link"
            name="link"
            placeholder="add link here"
            value={formData.link}
            onChange={handleInputChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel mb={0} fontSize="lg" htmlFor="media">
            Media
          </FormLabel>
          <FormHelperText mb={2} mt={0} id="media-helptext">
            Add image to be shared
          </FormHelperText>
          <Flex
            alignItems="center"
            justifyContent="center"
            borderWidth="1px"
            width="full"
            p="15px"
            my="15px"
            borderRadius="15px"
            onClick={handleDropClick}
            cursor="pointer"
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileChange}
            />
            <Stack alignItems="center" spacing={1}>
              <Stack direction="row" spacing={2} align="center">
                <Button colorScheme="teal">
                  <AddIcon fontSize={26} />
                </Button>
              </Stack>
              <Stack alignItems="center">
                <Text>or drag and drop</Text>
                <Text fontSize="sm" color="gray.500">
                  PNG, JPG or GIF up to 2MB
                </Text>
              </Stack>
            </Stack>
          </Flex>
          {mediaPreview && (
            <Box mt={4}>
              <FormHelperText mb={2} mt={0} id="media-preview-helptext">
                The selected image is displayed here
              </FormHelperText>
              <Box overflow={"hidden"} borderRadius={"10px"} pos={"relative"}>
                <CloseIcon
                  position={"absolute"}
                  top={"5px"}
                  right={"5px"}
                  cursor="pointer"
                  bgColor={"red.400"}
                  padding={"5px"}
                  borderRadius={"4px"}
                  boxSize={"20px"}
                  onClick={() => setMediaPreview(null)}
                />
                <Image
                  src={mediaPreview}
                  alt="media preview"
                  objectFit={"cover"}
                  height={"150px"}
                  width="full"
                />
              </Box>
            </Box>
          )}
        </FormControl>

        <FormControl>
          <FormLabel mb={0} fontSize="lg">
            Status
          </FormLabel>
          <FormHelperText mb={2} mt={0} id="status-helptext">
            On means this is shared and available for public.
          </FormHelperText>
          <Switch
            colorScheme="teal"
            name="status"
            isChecked={formData.status}
            onChange={handleSwitchChange}
          />
        </FormControl>
      </Stack>
      <ModalFooter>
        <Button mr={3} px={"25px"} type="submit" colorScheme="teal">
          Save
        </Button>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </form>
  );
};

export default AddEditLandingForm;
