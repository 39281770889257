import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import AddPermissionForm from "./components/AddPermissionForm";
import { IPermission } from "../../../interface/permission.interface";
import {
  createPermission,
  deletePermission,
  getAllPermissions,
  updatePermission,
} from "../../../Redux/action/permission.action";
import CommonTable from "../../../components/Common/CommonTable";
import PermissionTablesRow from "./components/PermissionTablesRow";

const PermissionPage = () => {
  const [data, setData] = useState<IPermission[]>([]); // set initial data
  const PAGE_SIZE = 6;
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState<IPermission[]>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const _getAllPermissions = useSelector(
    (state: { getAllPermissions: any }) => state.getAllPermissions
  );
  const {
    // loading: loadingPermissions,
    error,
    permissions,
  } = _getAllPermissions;

  const _updatePermission = useSelector(
    (state: { updatePermission: any }) => state.updatePermission
  );
  const {
    // loading: editLoading,
    // error: editError,
    permission,
  } = _updatePermission;

  const _deletePermission = useSelector(
    (state: { deletePermission: any }) => state.deletePermission
  );
  const {
    // loading: deleteLoading,
    // error: deleteError,
    permission: deleteSuccess,
  } = _deletePermission;

  const _createPermission = useSelector(
    (state: { createPermission: any }) => state.createPermission
  );
  const {
    // loading: disableLoading,
    // error: disableError,
    permission: createSuccess,
  } = _createPermission;

  // const _createAllPermission = useSelector(
  //   (state: { createAllPermission: any }) => state.createAllPermission
  // );
  // const {
  //   loading: enableLoading,
  //   error: enableError,
  //   permission: createAllSuccess,
  // } = _createAllPermission;

  const toastPositions = useSelector((state: any) => state.toastPosition);

  useEffect(() => {
    dispatch<any>(getAllPermissions(toastPositions, toast));
    setLoading(true);
    // console.log(loading);
  }, [
    dispatch,
    deleteSuccess,
    // searchTerm,
    permission,
    createSuccess,
    toast,
  ]);

  useEffect(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;

    setData(permissions);
    // console.log({ _getAllPermissions });
    setFilteredData(
      data
        ?.filter((d) =>
          d.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        )
        .slice(startIndex, endIndex)
    );
    setLoading(false);

    if (searchTerm) {
      setData(filteredData);
    }
  }, [searchTerm, permissions, data, page]);

  // Pagination logic...

  const handleAddButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFormSubmit = () => {
    // Handle form submission here
    setShowModal(false);
  };

  const handleAddPermission = (pData: any) => {
    // console.log("Adding Permission:", pData);
    dispatch<any>(
      createPermission(
        { name: pData.name, details: pData.details, status: pData.status },
        toastPositions,
        toast
      )
    );
  };

  return (
    <>
      <CommonTable
        title="Permissions"
        color="transparent"
        caption={["Name", "Description", "Actions"]}
        tableData={data}
        pageSize={6}
        handleAddButtonClick={handleAddButtonClick}
        handleCloseModal={handleCloseModal}
        handleFormSubmit={handleFormSubmit}
        showModal={showModal}
        error={error}
        loading={loading}
        formComponent={
          <AddPermissionForm
            closeModal={handleCloseModal}
            onSubmit={handleAddPermission}
          />
        }
        renderRow={(permission: IPermission) => (
          <PermissionTablesRow<IPermission>
            data={permission}
            onEdit={(editedPermission: IPermission) => {
              // Handle edit logic for Permissions
              // console.log(editedPermission);
              dispatch<any>(
                updatePermission(
                  editedPermission?._id,
                  {
                    name: editedPermission?.name,
                    details: editedPermission.details,
                    status: editedPermission.status,
                  },

                  toastPositions,
                  toast
                )
              );
            }}
            onDelete={(deletedPermission: IPermission) => {
              // Handle delete logic for Permissions
              // console.log(deletedPermission);
              dispatch<any>(
                deletePermission(deletedPermission?._id, toastPositions, toast)
              );
            }}
          />
        )}
      />
    </>
  );
};

export default PermissionPage;
